/* more-information info_box */
.row.images_.py-3 {
    img {
        height: 100%;
    }
}
.primary-container.block.info_box {
    margin-bottom: 1rem;
    background-color: $c-bolton-red;

    h3 {
        padding-top: 1.5rem;
    }
    a {
        color: $c-bolton-blue;

       
    }
}
.info_box {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
        width: 90vw;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 3rem;
        max-width: 1100px;
        align-items: center;
        @media(max-width: $screen-md){
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }
    &__col {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
    }
    &__contact-image {
        width: 200px;
        height: 200px;
        margin-left: auto;
        border: 6px solid $c-white;
        border-radius: 50%;
        @media(max-width: $screen-md){
            margin-left: auto;
            margin-right: auto;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    &__job-role {
        text-align: left;
        h5 {
            font-weight: 600;
            font-size: 1.1rem;
            margin-bottom: 5px;
            @media(max-width: $screen-md){
                text-align: center;
            }
        }
    }
    &__name {
        text-align: left;
        h5 {
            font-size: 2.2rem;
            font-weight: 600;
            color: $c-bolton-blue !important;
            margin-bottom: 5px;
            @media(max-width: $screen-md){
                text-align: center;
            }
        }
    }
    &__email {
        text-align: left;
        p {
            margin-bottom: 5px;
        }
        @media(max-width: $screen-md){
            text-align: center;
        }
    }
    &__copy-content {
        @media(max-width: $screen-md){
            text-align: center;
        }
    }

    // .copy_content {
    //     text-align: left;
    // }
}


/* one_col */
.page-child .primary-container.block.onecol {
    padding: 6em 0em;
}

/* shortcode  home page */
// .home .primary-container.block.shortcode {
//     margin-top: 115px;
// }
/* contact us page */
.page-id-108 .primary-container.block.onecol {
    top: 2.1em;
}