.text-videos {
    position: relative;
    padding-block: 4rem;

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
        width: 90vw;
        margin: 0 auto;
        max-width: 1100px;
        @media(max-width: $screen-md){
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }

    &__col {
        position: relative;
        h2 {
            font-family: "Verveine", sans-serif;
            color: $c-bolton-red;
        }
    }

    &__video-content {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        // border-bottom: 1px solid $c-bolton-red;
    }

    &__video-title {
        font-size: 2.25rem;
        color: $c-bolton-red;
    }

    &__video-intro {
        position: relative;
    }

}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
} 

.embed-container iframe,
.embed-container object,
.embed-container embed { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}