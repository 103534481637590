.header_layout-1 {
    margin-top: 115px;

    @media (min-width: 1000px) {
        min-height: calc(100vw / 3);
    }
    
}


@media screen and (min-width: 2000px) {
    .header_layout-1 {
        margin-top: 115px;
    }
}