.title-banner {
    position: relative;
    margin-top: 115px;
    padding-block: 2.5rem;
     h1 {
        text-align: center;
        padding-left: 3rem;
        padding-right: 3rem;
     }
     p {
        text-align: center;
        margin-block: 0.75em;
        font-weight: 400;
        padding-left: 3rem;
        padding-right: 3rem;
     }
}