.single-projects {
    position: relative;
    margin-top: 115px;
    
    &__heading-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        // max-height: 300px;
        height: 100%;
        @media(max-width: $screen-md){
            grid-template-columns: 1fr;
        }
    }

    &__title-desc {
        position: relative;
        padding-block: 4rem;
        padding-left: 3rem;
        padding-right: 3rem;
        background-color: $c-bolton-blue;
    }

    &__title-block {
        h1 {
            text-align: left;
        }
        p {
            color: #FFFFFF;
        }
        a {
            color: $c-bolton-red;
        }
    }

    &__project-image {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__session-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        @media(max-width: $screen-md){
            grid-template-columns: 1fr;
        }
    }
    &__session-text {
        display: block;
        position: relative;
        padding-block: 4rem;
        padding-left: 3rem;
        padding-right: 3rem;

        a {
            color: $c-bolton-red;
        }
        ul {
            list-style-type: disc;
            list-style-position: inside;
        }
        li::marker {
            color: $c-bolton-red;
        }
    }
    &__session-video {
        position: relative;
        // max-height: 400px;
        width: 100%;
    }
    &__venue-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        @media(max-width: $screen-md){
            grid-template-columns: 1fr;
        }
    }
    &__venue-map {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__venue-text {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-block: 4rem;
        padding-left: 3rem;
        padding-right: 3rem;
        justify-content: center;
        min-height: 400px;

        a {
            color: $c-bolton-red;
        }
    }
    

}

// Video responsive
.embed-container { 
    position: relative; 
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
} 

.embed-container iframe,
.embed-container object,
.embed-container embed { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}