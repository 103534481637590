.insights-card {
    display: flex;
    flex-direction: column;

    &__image {
        position: relative;
        display: block;
        // margin-bottom: 16px;
        height: 200px;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 5px;
        z-index: 2;

        img {
            border-radius: 5px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }


        &:after {
            content: '';
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom:0;
            font-size: 15px;
            font-size: clamp(12px, vw(15px), 15px);
            font-weight: 700;
            // background-color: $c-overlay;
            // opacity: 0;
            letter-spacing: 1px;

            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(37,48,104, 0.9) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,rgba(255, 255, 255, 0)), color-stop(100%,rgba(37,48,104, 0.9))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%,rgba(37,48,104, 0.9) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%,rgba(37,48,104, 0.9) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%,rgba(37,48,104, 0.9) 100%); /* IE10+ */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%,rgba(37,48,104, 0.9) 100%); /* W3C */

            border-radius: 5px;
            transition: background-color 1s ease-in-out;

        }

        &:hover {
            &:after {
                // opacity: 1;
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(232,66,65, 1) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255, 255, 255, 0)), color-stop(100%,rgba(232,66,65, 1))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%,rgba(232,66,65, 1) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%,rgba(232,66,65, 1) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%,rgba(232,66,65, 1) 100%); /* IE10+ */
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(232,66,65, 1) 100%); /* W3C */

                transition: background-color 1s ease-in-out;
                cursor: pointer;
            }
        }

    }

    &__title {
        margin-top: 10px;
        margin-bottom: 5px;

        h3 a {
            font-size: 25px;
            // font-weight: 700;
            // letter-spacing: -0.5px;

            @media (min-width: $screen-md) {
                font-size: 24px;
                font-size: clamp(25px, vw(30px), 30px);
            }
        }
    }

    &__excerpt {
        position: relative;
    }

    &__date {
        margin-bottom: 10px;
        font-weight: 700;
    }

    
}
