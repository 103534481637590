.testimonial-sliders {
    position: relative;
    padding-top: 80px;
    padding-bottom: 120px;

    &__container {
        position: relative;
    }

    &__title {
        display: flex;
        h2 {
            text-align: center;
            width: 100%;
            margin-bottom: 2rem;
            // line-height: 1.1rem;
        }
        h3 {
            text-align: center;
            width: 100%;
            margin-bottom: 2rem;
            // line-height: 1.1rem;
        }
    }

    &__testimonials {
        position: relative;
        // margin-left: 34%;
    }

    &__testimonial {
        position: relative;
        width: auto;
        border-radius: $border-rad;
        border: 1px solid #dfdfdf;
        background-color: #FFFFFF;
        padding: 40px 50px;
        margin-right: 20px;

        @media (min-width: $screen-md) {
            /* half-width cells for larger devices */
            width: 50%;
        }   
    }

    &__text-holder {
        display: flex;
        align-items: center;
        margin-top: 10px;

        img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin-right: 10px;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
    }

    &__author {
        position: relative;
    }

    // &__company {
    //     // font-size: clamp(14px, vw(18px), 18px);

    //     // span {
    //     //     color: $c-grey;
    //     // }
    // }

    // &__trustpilot-link {
    //     text-align: center;
    //     margin-top: 70px;
    //     h3, a {
    //         // font-size: clamp(16px, vw(24px), 24px);
    //         font-size: 18px;
    //         font-weight: 600;
    //         transition: all .33s ease-in-out;
    //     }
    //     a {
    //         color: $c-orange;
    //     }
    //     &:hover {
    //         svg {
    //             margin-left: 5px;
    //             transform: scale(1.2);
    //             transition: all .33s cubic-bezier(.12,.75,.4,1);
    //         }
    //     }
    // }
    .flickity-page-dots {
        bottom: -55px;
    }
    .flickity-page-dots .dot {
        width: 16px;
        height: 16px;
        margin: 0 5px;
        background-color: $c-bolton-blue;
        opacity: 1;
    }

    .flickity-page-dots .dot.is-selected {
        background-color: $c-bolton-red;
    }
    // .flickity-resize .carousel-cell {
    //     min-height: 100%;
    // }

}

