.contact-pod {
    position: relative;    
    padding: 4rem 0 2.5rem;

    // @media (min-width: $screen-md) {
    //     padding: 110px 0 60px;
    //     padding: clamp(60px, vw(110px), 110px) 0 clamp(30px, vw(60px), 60px);
    // }

    &__container {
        position: relative;
        // display: grid;
        align-items: center;
        // grid-gap: 30px;
        // gap: 30px;

        // @media (min-width: $screen-md) {
        //     grid-template-columns: repeat(2, 1fr);
        //     grid-gap: 70px;
        //     gap: 70px;
        //     gap: clamp(30px, vw(70px), 70px);
        // }
    }

    &__title {
        margin-bottom: 15px;
        line-height: 1;

        @media (min-width: $screen-md) {
            margin-bottom: 25px;
            margin-bottom: clamp(15px, vw(25px), 25px);
        }
    }

    &__text {
        margin-bottom: 30px;

        p {
            margin-bottom: 20px;
            margin-bottom: clamp(14px, vw(20px), 20px);
        }

    }

    // &__col {
    //     &:last-of-type {
    //         display: flex;
    //         flex-wrap: wrap;
    //         // gap: 10px;   

    //         > * + * {
    //             margin-left: 10px;
    //         }
            
    //         @media(min-width: $screen-md) {
    //             flex-wrap: nowrap;
    //         }
    //     }
    // }
    
}
