// Media queries
$screen-sm: 580px;
$screen-md: 768px;
$screen-lg: 1920px;

// Colours
$c-bolton-blue: #253068;
$c-bolton-red: #e84241;
$c-bolton-grey: #f2f2f2;
$c-white: #FFFFFF;

// Responsive scale mixin
$vw-viewport: 1919;
@function vw($size){
  $vw-context: $vw-viewport * 0.01 * 1px;
  // @return $size / $vw-context * 1vw; // Deprecation Warning: Using / for division outside of calc() is deprecated and will be removed in Dart Sass 2.0.0.
  @return calc($size / $vw-context) * 1vw;
  @return $size;
}

// Utilities
.bg-blue {
    background-color: $c-bolton-blue;
    h1 {
        color: #FFFFFF;
    }
    p {
        color: #FFFFFF;
    }
}
.bg-red {
    background-color: $c-bolton-red;
    h1 {
        color: $c-bolton-blue;
    }
    p {
        color: #FFFFFF;
    }
}
.bg-white {
    background-color: #FFFFFF;
}
.bg-grey {
    background-color: $c-bolton-grey;
}
p {
    line-height: 1.8;
}
$border-rad: 6px;
/* header main */
.header-main {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
    height: 115px;
    .primary-container {
        height: 115px;
    }
}
/* blog posts help fix header crops */
.single-post .primary-container.block img.attachment-full.size-full.wp-post-image {
    @media (min-width: 1000px) {
        min-height: calc(100vw / 3);
    }
}
.single-post .primary-container.block h1 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

/* red links on page */
.primary-container a {
    color: $c-bolton-red;
}
.primary-text p a {
    text-decoration: none;
}
.primary-text p a:hover {
    color: $c-bolton-red;
    text-decoration: underline;
}
a.button.button--telesto, a.button.button--telesto:hover {
    color: #FFFFFF;
}
.button--telesto:after, .button--telesto:before {
    background: unset;
}
/* gform button */
.gform_button.button, .gform_next_button.button, .gform_previous_button.button {
    padding: 0.5rem 1rem;
    background: $c-bolton-red;
    color: #fff;
    letter-spacing: 1px;
}
.gform_button.button:hover, .gform_next_button.button:hover, .gform_previous_button.button:hover {
    background: $c-bolton-blue;
}
/* woocommerce */
a.button.product_type_simple, a.button.product_type_phive_booking {
    padding: 0.75rem 1rem;
    background: $c-bolton-red;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 300;
    border-radius: 0px;
}
a.button.product_type_simple:hover, a.button.product_type_phive_booking:hover {
    background: $c-bolton-blue;
    color: $c-white;
}
@import "includes/flickity";

@import "header-layout-1";

@import "content";
@import "title-banner";
@import "image-banner";
@import "text-videos";
@import "contact-pod";
@import "testimonials-slider";

// Projects - Premier League Kicks
@import "posts";
@import "insights-cards";

@import "single-projects";
