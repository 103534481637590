.posts {
    margin-top: 4rem;
    padding-bottom: 40px;

    @media (min-width: $screen-md) {
        padding-bottom: 70px;
        padding-bottom: clamp(40px, vw(70px), 70px);
    }

    &-header {
        // text-align: center;
        padding: 60px 0;

        @media (min-width: $screen-md) {
            padding: 60px 0;
            padding: clamp(30px, vw(60px), 60px) 0;
        }

        &__markers {
            display: none;

            @media (min-width: $screen-md) {
                display: block;
                top: 70px;
                right: 100%;
            }

            @media (min-width: $screen-md) {
                top: 160px;
                top: clamp(70px, vw(160px), 160px);
            }
        }
    }

    &-listing {
        position: relative;
        // display: grid;
        // grid-gap: 40px;
        // gap: 40px;

        // @media (min-width: $screen-md) {
        //     grid-template-columns: 1fr 1fr;
        //     grid-gap: 30px;
        //     gap: 30px;
        //     gap: clamp(40px,vw(120px), 120px);
        // }

        &__filter {
            margin-top: 3rem;
            order : 2;

            @media (min-width: $screen-md) {
                order: 1;   
            }

            li {
                display: inline-block;
                // font-family: $f-headline;
                font-weight: 400;
                font-size: 22px;
                list-style-type: none;
                margin-bottom: 20px;
                padding-right: 25px;

                @media (min-width: $screen-md) {
                    position: sticky;
                    top: 40px;
                    font-size: 30px;
                    font-size: clamp(22px, vw(30px), 30px);
                }

                

                a {
                    display: inline-block;
                    margin-bottom: 8px;
                    // font-family: $f-body;
                    font-size: 16px;
                    // font-weight: 700;
                    // color: $c-black;
                    
                    @media (min-width: $screen-md) {
                        font-size: 16px;
                        font-size: clamp(16px, vw(20px), 20px);
                    }
                }
            }
            .current-cat a,
            .blog & .cat-item-all a {
                color: $c-bolton-red;
            }
        }

        &__load-more {
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
        
        &__form {
            display:none;

            @media (min-width: $screen-md) {
                display: block;
                margin-bottom: 40px;
            }

            &-title {
                margin-bottom: 20px;
            }

            &-intro {
                font-size: clamp(16px, vw(18px), 18px);
                margin-bottom: 20px;
            }
        }

        &__posts {
            display: grid;
            grid-gap: 20px;
            gap: 20px;
            order: 1;

            @media (min-width: $screen-sm) {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 20px;
                gap: 20px;
                gap: clamp(20px, vw(40px), 40px);
            }

            @media (min-width: $screen-md) {
                order: 2;   
            }
        }

        &__pagination {
            grid-column: 1 / -1;
            display: flex;
            align-items: center;
            // gap: 15px;

            > * + * {
                margin-left: 15px;
            }            

            .page-numbers {
                padding: 10px 10px 10px 0;
                font-weight: 700;
                font-size:12px;

                @media (min-width: $screen-md) {
                    font-size: 15px;
                    font-size: clamp(12px, vw(15px), 15px);   
                }

                &.current {
                    color: $c-bolton-red;
                }
            }
        }
    }

}

.mobile-only {
    display: block!important;
    @media (min-width: $screen-md) {
        display: none!important;
    }
}

.text-image__slider-controls {
    margin-top: 20px;
}

// FacetWP
.facetwp-facet-categories {
    display: flex;
    // font-weight: 700;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    font-size: 16px;
    @media(max-width: $screen-sm) {
        font-size: 14px;
        justify-content: flex-start;
        gap: 10px;
    }
}
.facetwp-checkbox {
    // background: url('../../svgs/') 0 50% no-repeat !important;
    background-size: 18px 18px !important;
    margin-bottom: 4px !important;
    padding-left: 25px !important;
}
// .facetwp-checkbox.checked {
//     background-image: url('../../svgs/') !important;
// }